<template>
    <div>
        <flow :lists="lists" @searchVal="searchVal"></flow>
    </div>
</template>
<script>
import api from '../../api/index'
import flow from './components/index.vue'
export default {
    data() {
        return {
            lists:[]
        };
    },
    components: {
        flow
    },
    methods: {
        
    },
    watch:{
        $route: {
            handler:  function (val, oldVal){
                this.list(val.query.search)
            },
            // 深度观察监听
            deep:  true
        }
    },
    mounted() {
        this.list(this.$route.query.search)
    },
    methods: {
        list(v) {
            var obj = {
				song_cate_id: '', 
				keywords: v, 
				pageNum: 1, 
				pageSize: 50
			}
			api.search(obj).then((res) => {
				if (res.data.code == 200) {
                    res.data.result.list.forEach(item => {
                        item.heights =Math.round(Math.random()*230+90)
                    });
                    this.lists = res.data.result.list
                    // console.log( this.lists );
				}
			})
        },
        searchVal(val){
            var en = btoa(val.id);
            this.$router.push({path: 'play', query: {id: en} })
        }
    }
};
</script>
  
 
<style scoped>

</style>