<template>
  <div>
    <div class="masonry">
      <div class="item" v-for="item in lists">
        <div class="item__content" :style="{minHeight:item.heights+'px',background:bjolorFun(item.song_colors)   }" @click="btnFun(item)">
          <img class="middile_img"  v-if="item&&item.song_cover" :src=" 'http://82.157.140.72/test' + (item&&item.song_cover)">
          <div class="lf">{{item.cate_info.category_name}}</div>
          <div class="lf" :style="{color:dynamicColorFun(item.song_colors)}"> {{item.song_author}}</div>
          <div class="lf" :style="{color:dynamicColorFun(item.song_colors)}">{{item.song_name}}</div>
          <div class="lf" :style="{color:dynamicColorFun(item.song_colors)}">{{item.song_desc}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {bjolor,dynamicColor} from '../../../components/js/index'
export default {
  props: ["lists"],
  data() {

    return {

    };
  },
  components: {

  },
  created() {


  },

  mounted() {

  },
  methods: {
    btnFun(val){
      this.$emit("searchVal" , val)
    },
    bjolorFun(v){
      return bjolor(v)
    },
    dynamicColorFun(v){
      return dynamicColor(v)
    },
  },
};
</script>


<style scoped>
.lf{
  margin-left: 5px;
}

.middile_img{
  padding: 12px;
  box-sizing: border-box;
}

.masonry {
  -moz-column-count: 1;
  column-count: 1;
  /* 设置列数 */
  -moz-column-gap: 0;
  column-gap: 0;
  /* 设置列间距 */
  counter-reset: item-counter;
  margin-top: 60px;
  margin-bottom: 100px;
}

/* 根据不同的屏幕宽度 设置不同的列数*/
@media screen and (min-width: 400px) {
  .masonry {
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: 600px) {
  .masonry {
    -moz-column-count: 3;
    column-count: 3;
  }
}

@media screen and (min-width: 800px) {
  .masonry {
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media screen and (min-width: 1000px) {
  .masonry {
    -moz-column-count: 5;
    column-count: 5;
  }
}
@media screen and (min-width: 1100px) {
  .masonry {
    -moz-column-count: 6;
    column-count: 6;
  }
}
@media screen and (min-width: 1200px) {
  .masonry {
    -moz-column-count: 7;
    column-count:7;
  }
}
@media screen and (min-width: 1300px) {
  .masonry {
    -moz-column-count: 8;
    column-count:8;
  }
}
@media screen and (min-width: 1400px) {
  .masonry {
    -moz-column-count: 9;
    column-count:9;
  }
}
@media screen and (min-width: 1500px) {
  .masonry {
    -moz-column-count: 10;
    column-count:10;
  }
}



.item {
  box-sizing: border-box;
  -moz-column-break-inside: avoid;
  break-inside: avoid;
  padding: 10px;
  counter-increment: item-counter;
}



.item__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  align-content: center;
  justify-content: space-around;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #6a6a6a;
}

</style>